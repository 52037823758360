import numeral from 'numeral';
import dayjs from 'dayjs';
import formatMoney from '@/libs/format_money';
import 'dayjs/locale/ru';
import GetOrderParam from './getOrderParam';

dayjs.locale('ru');

const capitalize = (string = '') => [...string].map(
  (char, index) => (index ? char : char.toUpperCase()),
).join('');

export default ({
  $t, OrderStatus, OrderInfo, acc, email, month, type,
}) => {
  const getOrderParam = GetOrderParam({
    OrderInfo, OrderStatus, type, defaultVal: '–', email,
  });

  const getFormatedCommission = (orderStatus) => {
    const commission = numeral(getOrderParam('feeCalculationResult', '0')).divide(100).value() || 0;
    const totalWithNoCommission = numeral(getOrderParam('totalWithNoCommission', '0')).value();
    const total = numeral(totalWithNoCommission).add(commission).value();

    if (orderStatus === 'DECLINED' || orderStatus === 'CANCELED') {
      return {
        commission: '-', totalWithNoCommission: '-', total: `${formatMoney(total)} ₽`,
      };
    }

    return {
      commission: `${formatMoney(commission)} ₽`,
      totalWithNoCommission: `${formatMoney(totalWithNoCommission)} ₽`,
      total: `${formatMoney(total)} ₽`,
    };
  };

  const { commission, totalWithNoCommission, total } = getFormatedCommission(OrderStatus);

  return [
    [
      { val: $t('operationStatus') },
      { val: getOrderParam('OrderStatus') },
    ],
    [
      { val: $t('operationDateAndTime') },
      { val: getOrderParam('TranDateTime') },
    ],
    [
      { val: $t('transactionNumber') },
      { val: getOrderParam('transactionNumber') },
    ],
    [
      { val: $t('cardNumber') },
      { val: getOrderParam('PAN') },
    ],
    [
      { val: $t('cardHolder') },
      { val: getOrderParam('CardHolderName') },
    ],
    [
      { val: $t('accountNumber') },
      { val: acc },
    ],
    [
      { val: $t('paymentPeriod') },
      { val: capitalize(dayjs(month).format('MMMM YYYY')) },
    ],
    [
      { val: $t('totalReceiptSumWithoutPayerCommission') },
      { val: totalWithNoCommission },
    ],
    [
      { val: $t('payerCommission') },
      { val: commission },
    ],
    [
      { val: $t('totalReceiptSumWithPayerCommission') },
      { val: total },
    ],
    [
      { val: $t('moneyTransferOperator') },
      { val: $t('operatorFullName') },
    ],
    [
      { val: $t('INN') },
      { val: $t('operatorINN') },
    ],
    // [
    //   { val: $t('agreement') },
    //   { val: '' },
    // ],
    [
      { val: $t('location') },
      { val: $t('operatorLocation') },
    ],
    // [
    //   { val: $t('phone') },
    //   { val: '' },
    // ],
    [
      { val: $t('email') },
      { val: getOrderParam('email') },
    ],
  ];
};
