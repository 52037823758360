import dayjs from 'dayjs';
import numeral from 'numeral';
import config from '@/config/client';

export default ({
  OrderInfo, OrderID, OrderStatus, type, defaultVal, email,
}) => (name, defVal) => {
  const mapping = {
    Merchant: {
      apple: () => 'best2pay',
      google: () => 'best2pay',
      card: () => config.PGMerchant,
      qr: () => config.PGMerchantFPS,
    },
    OrderID: {
      apple: () => OrderInfo.operations.operation.id,
      google: () => OrderInfo.operations.operation.id,
      card: () => OrderID,
      qr: () => OrderID,
    },
    OrderStatus: {
      apple: () => OrderInfo.state,
      google: () => OrderInfo.state,
      card: () => OrderStatus,
      qr: () => OrderStatus,
    },
    PAN: {
      apple: () => OrderInfo.operations.operation.pan,
      google: () => OrderInfo.operations.operation.pan,
    },
    CardBrand: {
      apple: () => null,
      google: () => null,
    },
    CardHolderName: {
      apple: () => OrderInfo.operations.operation.name,
      google: () => OrderInfo.operations.operation.name,
    },
    APPROVAL: {
      apple: () => OrderInfo.operations.operation.approval_code,
      google: () => OrderInfo.operations.operation.approval_code,
    },
    TranDateTime: {
      apple: () => dayjs(
        OrderInfo.operations.operation.date.replaceAll('.', '/'),
      ).format('DD/MM/YYYY HH:mm:ss'),
      google: () => dayjs(
        OrderInfo.operations.operation.date.replaceAll('.', '/'),
      ).format('DD/MM/YYYY HH:mm:ss'),
      card: () => (OrderInfo.payDate
        ? dayjs(OrderInfo.payDate).format('DD/MM/YYYY HH:mm:ss')
        : OrderInfo.payDate),
      qr: () => (OrderInfo.lastUpdateDate
        ? dayjs(OrderInfo.lastUpdateDate).format('DD/MM/YYYY HH:mm:ss')
        : OrderInfo.lastUpdateDate),
    },
    TwoID: {
      apple: () => OrderInfo.id,
      google: () => OrderInfo.id,
      card: () => OrderInfo.twoId,
      qr: () => OrderInfo.twoId,
    },
    transactionNumber: {
      apple: () => OrderInfo.operations.operation.id,
      google: () => OrderInfo.operations.operation.id,
      card: () => OrderInfo.twoId,
      qr: () => OrderInfo.twoId,
    },
    ResponseDescription: {
      apple: () => OrderInfo.operations.operation.message,
      google: () => OrderInfo.operations.operation.message,
    },
    email: {
      apple: () => OrderInfo.operations.operation.email,
      google: () => OrderInfo.operations.operation.email,
      card: () => email,
      qr: () => email,
    },
    totalWithNoCommission: {
      apple: () => numeral(OrderInfo.operations.operation.amount).divide(100).value(),
      google: () => numeral(OrderInfo.operations.operation.amount).divide(100).value(),
      card: () => numeral(OrderInfo.Amount).divide(100).value(),
      qr: () => numeral(OrderInfo.Amount).divide(100).value(),
    },
    feeCalculationResult: {
      apple: () => OrderInfo.operations.operation.fee,
      google: () => OrderInfo.operations.operation.fee,
      card: () => OrderInfo.Fee,
    },
  };

  return mapping?.[name]?.[type]?.()
      || OrderInfo?.OrderParams?.row?.find(({ PARAMNAME }) => PARAMNAME === name)?.VAL
      || defVal
      || defaultVal;
};
