<template lang="pug">
#services-review
  h1 {{$t('paymentResult')}}
  .preloader(v-if="fsm.state === 'orderInfoLoading' && fsm.state !== 'error'")
    Spinner(color="base")
    span {{$t('paymentDataLoading')}}
    .loading-note {{$t('pleaseKeepWindowOpenUntilResult')}}

  SystemMessage.system-message(
    v-if="systemMessage && systemMessage.type"
    :type="systemMessage.type"
    :title="$t(systemMessage.title)"
    :desc="$t(systemMessage.desc)"
  )

  .content(v-if="paymentInfo" :class="{'text-center': qrImage && !isOrderStatusFinal}")
    h3 {{$t(title)}}
    .qr-block(v-if="qrImage && !isOrderStatusFinal")
      p.desc {{$t('pleaseScanQRFromMobileOrGoDirectly')}}
      a.qr(:href="$route.query.qr" target="_blank" tabindex="2"): img(:src="qrImage")
      a.link(:href="$route.query.qr" target="_blank" tabindex="3") {{$t('directLink')}}
    a.link(
      :href="$route.query.qr"
      target="_blank"
      v-if="isFPSActive"
    ) {{$t('goToPaymentWithFPS')}}
    Table(:data="paymentInfo" v-if="isOrderStatusFinal")

  .actions(v-if="fsm.state === 'done' || fsm.state === 'error'")
    Button.cancel(@click.native="cancel" v-if="!isOrderStatusFinal" tabindex="7") {{$t('cancel')}}
    Button.cancel(
      @click.native="toHome" v-if="isOrderStatusFinal" tabindex="5") {{$t('newPayment')}}
    Button.action(
      :href="receiptURL()"
      v-if="CanDownloadReceipt" tabindex="6"
    ) {{$t('printReceipt')}}
    Button.action(
      v-else-if="fsm.state === 'error' && !isExpired"
      @click.native="processPayment" tabindex="4") {{$t('tryAgain')}}
  div(tabindex="10" onFocus="document.querySelectorAll('[tabindex]')[1].focus()")
</template>

<script>
import qrcode from 'qrcode-generator';
import config from '@/config/client';
import api from '@/libs/api';
import isMobile from '@/libs/is_mobile';

import genPaymentData from './genPaymentData';
import createStateMachine from './createStateMachine';
import isOrderStatusFinal from './isOrderStatusFinal';
import messages from './messages.yml';
import b2pRCMessages from './b2pReasonCodeMessages.yml';

import Spinner from '#c/uikit/Spinner.vue';
import Button from '#c/uikit/Button.vue';
import Table from '#c/uikit/Table.vue';
import SystemMessage from '#c/uikit/SystemMessage.vue';

export default {
  name: 'Result',

  created() {
    this.fsm = createStateMachine(this);
    this.processPayment();
  },

  mounted() {
    document.querySelector('[tabindex="1"]').focus();
    this.setOperationStatus();
  },

  setup() {
    return {
      loadPGOrderInfoTask: api.createTask('PGGetOrderInfo', {
        retryOnError: () => true,
        delay: 6000,
      }),
      loadPGOrderStatusTask: api.createTask('PGGetOrderStatus', {
        retryOnError: () => true,
      }),
      // должный дождаться ответа, что выставленный QR оплачен
      updateInvoiceTask: api.createTask('ReqUpdateInvoice', {
        retryOnError: (err) => !err?.fromAPI,
      }),
      // только после этого вызвать данный метод ReqConfirmInvoice
      confirmInvoiceTask: api.createTask('ReqConfirmInvoice', {
        retryOnError: (err) => !err?.fromAPI,
      }),
      loadBPOrderInfoTask: api.createTask('BPGetOrderInfo', {
        retryOnError: () => true,
      }),
      registerReceiptTask: api.createTask('RegisterReceipt', {
        retryOnError: () => true,
      }),
    };
  },

  data() {
    return {
      order: null,
      paymentInfo: null,
      fsm: null,
      qrImage: null,
      isMobile: isMobile(),
    };
  },

  watch: {
    OrderStatus() {
      this.setOperationStatus();
    },
  },

  methods: {
    goToFPS() {
      if (!this.isFPSActive) return;
      window.open(this.$route.query.qr, '_blank');
    },

    async processPayment() {
      const { type } = this.$route.query;

      this.fsm.reset();

      if (type === 'apple' || type === 'google') {
        this.fsm.loadBPInfo();
        return;
      }

      this.fsm.loadInfo();
    },

    setOperationStatus() {
      if (!this.OrderStatus) return;
      const operationStatusRow = this.paymentInfo
        ?.find(([{ val }]) => val === this.$t('operationStatus'));
      if (!operationStatusRow) return;
      operationStatusRow[1].val = this.OrderStatus;
    },

    fillPaymentData({ OrderStatus, OrderInfo }) {
      const {
        acc, email, month, type,
      } = this.$route.query;

      this.paymentInfo = genPaymentData({
        $t: this.$t.bind(this),
        OrderInfo,
        OrderStatus: this.OrderStatus || OrderStatus,
        acc,
        email,
        month,
        type,
      });

      this.$nextTick(() => this.generateQR());
    },

    generateQR() {
      if (this.isMobile) return;

      const { qr: URL } = this.$route.query;
      if (!URL) return;
      const qr = qrcode(0, 'H');
      qr.addData(URL);
      qr.make();
      this.qrImage = qr.createDataURL(10, 0);
    },

    cancel() {
      this.$router.push(
        {
          name: 'result',
          query: {
            ...this.$route.query,
            status: 'CANCELED',
          },
        },
      );
    },

    receiptURL() {
      const { InvoiceUID, acc } = this.$route.query;
      const PGResponseURL = new URL(`${config.API}/DownloadReceipt.pdf`);
      PGResponseURL.searchParams.set('InvoiceUID', InvoiceUID);
      PGResponseURL.searchParams.set(
        'filename',
        `${this.$t('receiptForPayment')}${acc}.pdf`,
      );
      return PGResponseURL;
    },

    toHome() {
      this.$router.push({ name: 'home' });
    },
  },

  computed: {

    CanDownloadReceipt() {
      const status = this.OrderStatus;

      console.log('status', status);

      if (this.$route?.query?.qr) {
        return status === 'ACCEPTED';
      }

      return status === 'APPROVED' || status === 'COMPLETED';
    },

    OrderStatus() {
      const queryStatus = this.$route.query.status;
      if (queryStatus) return queryStatus;

      const orderStatusResponse = this.loadPGOrderStatusTask?.last?.value?.data;

      return this.$route?.query?.qr
        ? orderStatusResponse?.QRStatus
        : orderStatusResponse?.Order?.OrderStatus || orderStatusResponse?.order?.state;
    },

    isFPSActive() {
      return this.$route.query.qr && this.isMobile && !this.isOrderStatusFinal;
    },

    OrderInfo() {
      return this.loadPGOrderInfoTask?.last?.value
        ?.data?.Order?.row;
    },

    isOrderStatusFinal() {
      if (this.$route.query.isB2PRedirect) return true;
      return isOrderStatusFinal(this.OrderStatus);
    },

    title() {
      const { type } = this.$route.query;
      const mapping = {
        qr: 'paymentWithFPS',
        card: 'servicesCashlessPayment',
      };
      return mapping[type];
    },

    isExpired() {
      return this.$route.query.status === 'EXPIRED';
    },

    systemMessage() {
      if (this.isExpired) {
        return {
          type: 'info',
          title: 'warning',
          desc: 'paymentExpired',
        };
      }
      if (this.fsm.state === 'error') {
        return {
          type: 'error',
          title: 'ERROR',
          desc: 'paymentProcessingError',
        };
      }

      if (this.$route.query.code && this.$route.query.isB2PRedirect) {
        return b2pRCMessages[this.$route.query.code];
      }

      if (this.fsm.state === 'done') {
        return messages[this.OrderStatus || 'UNKNOWN'];
      }
      return null;
    },
  },

  components: {
    Spinner,
    Button,
    SystemMessage,
    Table,
  },
};
</script>

<style scoped lang="stylus">
@import '~#a/style/config'

h1
  margin-bottom: 16px
  @media(max-width: 767px)
    font-size: 32px

.loading-note
  font-size: 16px
  margin-top: 16px

.preloader
  font-size: 30px
  text-align: center
  padding: 4em 0
  color: $color-base
  @media(max-width: 767px)
    font-size: 20px
  .spinner
    font-size: 1.3em
    margin-right: 0.3em
  span
    vertical-align: top
    padding-top: 0.15em
    display: inline-block

h3
  margin-bottom: 16px
  margin-top: 16px

.link
  color: $color-base
  font-size: 18px
  border-bottom: 1px solid @color
  padding-top: 8px
  display: inline-block

.qr-block
  .desc
    margin: auto
    margin-bottom: 32px
    max-width: 500px

.qr
  display: block
  max-width: 300px
  max-height: @width
  border: 1px solid $color-border
  border-radius: 8px
  overflow: hidden
  margin auto
  padding: 8px
  img
    width: 100%
    height: 100%
    vertical-align: middle

.text-center
  text-align: center

table
  margin-bottom: 16px

.actions
  margin-top: 32px
  clearfix()
  .action
    float: right
    @media(max-width: 767px)
      float: none

  @media(max-width: 767px)
    display: flex
    flex-direction: column-reverse
    width: 100%
    position: sticky
    bottom: 10px
    button
      padding-left: 16px
      padding-right: @padding-left
      width: 100%
      margin-top: 16px
      &:last-child
        margin-top: 0

.cancel
  background-color: lighten($color-label, 25%)
  &:hover
    background-color: lighten($color-label, 10%)
  &:active
    background-color: $color-label
</style>
