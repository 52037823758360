const finalStatuses = [
  'APPROVED',
  'ACCEPTED',
  'COMPLETED',
  'CANCELED',
  'DECLINED',
  'REVERSED',
  'REFUNDED',
  'PREAUTH-APPROVED',
  'EXPIRED',
  'ERROR',
];

export default (OrderStatus) => finalStatuses.includes(OrderStatus);
