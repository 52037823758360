<template lang="pug">
table(v-if="data")
  tbody
    tr(v-for="row in data")
      td(v-for="cell in row"): .cell {{cell.val}}
</template>

<script>
export default {
  name: 'Table',

  props: ['data'],
};
</script>

<style scoped lang="stylus">
@import '~#a/style/config'

table
  border-collapse: collapse
  width: 100%
  @media(max-width: 767px)
    font-size: 12px

td
  padding-bottom: 10px
  padding-top: @padding-bottom
  padding-left: 10px
  border-bottom: $color-border 1px solid
  &:first-child
    padding-left: 0

tr
  &:hover
    background-color: rgba($color-base, .03)
  &:last-child
    td
      border-bottom: 0
</style>
