import numeral from 'numeral';
import config from '@/config/client';
import genInvoiceData from './genInvoiceData';
import isOrderStatusFinal from './isOrderStatusFinal';
import GetOrderParam from './getOrderParam';
import getMerchantId from '../../utils/getMerchantId';

export default (self) => ({
  async loadOrderInfo(OrderStatus) {
    const {
      OrderID, SessionID, type, InvoiceUID, org,
    } = self.$route.query;
    if (!OrderID || !SessionID || !type) {
      throw new Error('Missing required order params');
    }

    const response = await self.loadPGOrderInfoTask.perform({
      OrderID, SessionID, Merchant: getMerchantId(type), type, org,
    });

    const result = response.data.Order.row;
    result.fee = 0;

    let orderCompeted = false;

    if (type === 'qr' && OrderStatus === 'ACCEPTED') {
      orderCompeted = true;
    } else {
      orderCompeted = OrderStatus === 'APPROVED' || OrderStatus === 'COMPLETED';
    }

    try {
      if (orderCompeted) {
        const reqConfirm = await self.confirmInvoiceTask.perform({
          ...genInvoiceData({
            InvoiceUID, OrderStatus, OrderInfo: result, OrderID, type,
          }),
          PaymentType: type,
        });

        const fee = reqConfirm?.data?.SummFee;
        result.fee = fee ? numeral(fee).multiply(100).value() : 0;
      }
    } catch (e) {
      console.error(e.message);
    }

    return result;
  },

  async loadOrderStatus() {
    const {
      OrderID, SessionID, type, org,
    } = self.$route.query;
    if (!OrderID || !SessionID || !type) {
      throw new Error('Missing required order params');
    }

    const response = await self.loadPGOrderStatusTask.perform({
      OrderID, SessionID, Merchant: getMerchantId(type), type, org,
    });

    const { data } = response || {};

    return type === 'qr'
      ? data?.QRStatus
      : data?.Order?.OrderStatus;
  },

  async loadBPOrderInfo() {
    const { order_id, InvoiceUID, id } = self.$route.query;
    if (!order_id || !InvoiceUID) {
      throw new Error('Missing required best2pay order params');
    }

    const response = await self.loadBPOrderInfoTask.perform({
      id: order_id !== 'null' ? order_id : id,
      reference: InvoiceUID,
    });

    return response.data.order;
  },

  async updateInvoice({ OrderStatus, OrderInfo }) {
    const {
      OrderID, SessionID, type, org,
    } = self.$route.query;

    if (!OrderID || !SessionID || !type) {
      throw new Error('Missing required order params');
    }

    const mapping = {
      CREATED: 1,
      APPROVED: 2,
      COMPLETED: 2,
      'ON-LOCK': 3,
      'ON-PAYMENT': 3,
      'ON-REFUND': 3,
      DECLINED: 4,
      CANCELED: 5,
      EXPIRED: 5,
      'PREAUTH-APPROVED': 3,

      ACCEPTED: 2,
      IN_PROGRESS: 3,
      REJECTED: 4,
      ERROR: 4,
    };

    // @TODO How to process unknown order status?
    const InvoiceStateCode = mapping[OrderStatus || OrderInfo.Orderstatus];
    if (!InvoiceStateCode) console.warn('UPDATE INVOICE: неизвестный  статус');

    console.log('updateInvoice');
    console.log('InvoiceStateCode: ', InvoiceStateCode, OrderStatus, OrderInfo);

    if (InvoiceStateCode === mapping.APPROVED || (type === 'qr' && InvoiceStateCode === mapping.ACCEPTED)) {
      const response = await self.loadPGOrderInfoTask.perform({
        OrderID, SessionID, Merchant: getMerchantId(type), type, org,
      });
      const result = response.data.Order.row;
      console.log('getRequests DONE', result);
      return this.confirmInvoice({ OrderStatus, OrderInfo: result });
    }

    const {
      InvoiceUID, order_id,
    } = self.$route.query;

    if (!InvoiceUID && (!OrderID || !order_id)) {
      throw new Error('Missing required order params for the invoice update');
    }
    return self.updateInvoiceTask.perform({
      ...genInvoiceData({
        InvoiceUID, OrderStatus, OrderInfo, OrderID, type,
      }),
      InvoiceStateCode,
      PaymentType: type,
    })?.data;
  },

  async registerReceipt({ OrderInfo, OrderStatus }) {
    const {
      InvoiceUID, type, email, org,
    } = self.$route.query;

    const getOrderParam = GetOrderParam({
      OrderInfo, OrderStatus, type, email,
    });
    const totalWithNoCommission = getOrderParam('totalWithNoCommission', '0');
    const commission = numeral(getOrderParam('feeCalculationResult', '0')).divide(100).value() || 0;
    const total = numeral(totalWithNoCommission).add(commission).value();
    const orgName = self?.$store?.state?.organizations?.find((_org) => _org.code === org)?.label;

    return self.registerReceiptTask.perform({
      InvoiceUID,
      email: getOrderParam('email'),
      orgName,
      total,
    });
  },

  async confirmInvoice({ OrderStatus, OrderInfo }) {
    const {
      InvoiceUID, OrderID, order_id, type, receiptRegistered,
    } = self.$route.query;

    if (receiptRegistered) {
      return true;
    }

    if (!InvoiceUID && (!OrderID || !order_id)) {
      throw new Error('Missing required order params for the invoice confirm');
    }

    const invoiceData = await self.confirmInvoiceTask.perform({
      ...genInvoiceData({
        InvoiceUID, OrderStatus, OrderInfo, OrderID, type,
      }),
      PaymentType: type,
    });

    if (config.registerReceipt) {
      await this.registerReceipt({
        OrderInfo, OrderStatus,
      });
    }

    self.$router.push(
      {
        name: 'result',
        query: {
          ...self.$route.query,
          receiptRegistered: true,
        },
      },
    );

    return invoiceData?.data;
  },

  async updateOrderUntilFinal() {
    if (isOrderStatusFinal(self.OrderStatus)) return null;

    // let timeout;

    // clearTimeout(timeout);

    // const pause = (ms) => new Promise(((resolve) => {
    //   timeout = setTimeout(() => {
    //     resolve;
    //   }, ms);
    // }));

    try {
      await this.loadOrderStatus();
      self.setOperationStatus();
      await this.updateInvoice(self);
    } catch (e) {
      return this.updateOrderUntilFinal();
    }

    if (!isOrderStatusFinal(self.OrderStatus)) {
      await this.updateOrderUntilFinal();
      return null;
    }

    return true;
  },

});
