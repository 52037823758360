import StateMachine from 'javascript-state-machine';
import genRequests from './genRequests';

export default (self) => {
  const transition = (name, data) => setTimeout(
    () => self.fsm[name](data), 0,
  );

  const requests = genRequests(self);

  return new StateMachine({
    init: 'init',
    transitions: [
      { name: 'loadInfo', from: 'init', to: 'orderInfoLoading' },
      { name: 'updateInvoice', from: 'orderInfoLoading', to: 'invoiceUpdating' },
      { name: 'genPaymentData', from: 'invoiceConfirmed', to: 'done' },
      { name: 'loadStatus', from: '*', to: 'orderStatusLoading' },
      { name: 'invoiceConfirm', from: 'orderStatusLoading', to: 'invoiceConfirmed' },
      { name: 'generateQR', from: 'invoiceUpdating', to: 'qrCreated' },
      { name: 'loadBPInfo', from: 'init', to: 'orderInfoLoading' },

      { name: 'error', from: '*', to: 'error' },
      { name: 'reset', from: '*', to: 'init' },
    ],
    methods: {
      onLoadStatus: (fsm, { OrderInfo }) => requests
        .loadOrderStatus()
        .then(async (OrderStatus) => {
          const { type } = self.$route.query;

          let orderCompeted = false;

          if (type === 'qr' && OrderStatus === 'ACCEPTED') {
            orderCompeted = true;
          } else {
            orderCompeted = OrderStatus === 'APPROVED' || OrderStatus === 'COMPLETED';
          }
          self.setOperationStatus();
          self.fillPaymentData({ OrderStatus, OrderInfo });
          if (orderCompeted) {
            transition('invoiceConfirm', { OrderInfo, OrderStatus });
          } else {
            const delay = type === 'qr' ? 5000 : 60000;
            await new Promise((resolve) => setTimeout(() => resolve(true), delay));
            transition('loadStatus', { OrderInfo });
          }
        }).catch((e) => {
          console.error('onLoadStatus', e);
          transition('error');
        }),

      onLoadInfo: (fsm, OrderStatus) => requests
        .loadOrderInfo(OrderStatus)
        .then((OrderInfo) => {
          transition('updateInvoice', { OrderStatus, OrderInfo });
        })
        .catch((e) => {
          console.error('onLoadStatus', e);
          transition('error');
        }),

      onGenerateQR: (fsm, { OrderInfo }) => {
        self.generateQR();
        transition('loadStatus', { OrderInfo });
      },

      onLoadBPInfo: () => requests
        .loadBPOrderInfo()
        .then((OrderInfo) => transition('updateInvoice', { OrderInfo }))
        .catch((e) => {
          console.error('onLoadStatus', e);
          transition('error');
        }),

      onUpdateInvoice: (fsm, { OrderStatus, OrderInfo }) => requests
        .updateInvoice({ OrderStatus, OrderInfo })
        .then(() => {
          const { type } = self.$route.query;
          if (type === 'card') {
            transition('loadStatus', { OrderStatus, OrderInfo });
          } else {
            transition('generateQR', { OrderInfo });
          }
        })
        .catch((e) => {
          console.error('onLoadStatus', e);
        }),

      onInvoiceConfirm: (fsm, data) => requests
        .confirmInvoice(data)
        .then(() => {
          transition('genPaymentData', data);
        })
        .catch((e) => {
          console.error('onLoadStatus', e);
        }),

      onGenPaymentData: (fsm, data) => {
        self.fillPaymentData(data);
      },

      onDone: () => {
        // requests.updateOrderUntilFinal();
      },
    },
  });
};
