import GetOrderParam from './getOrderParam';

export default ({
  InvoiceUID, OrderStatus, OrderInfo, OrderID, type,
}) => {
  const getOrderParam = GetOrderParam({
    OrderInfo, OrderID, OrderStatus, type,
  });

  return {
    UID: InvoiceUID,
    Additional: {
      Merchant: getOrderParam('Merchant'),
      OrderID: getOrderParam('OrderID'),
      OrderStatus: getOrderParam('OrderStatus'),
      PAN: getOrderParam('PAN'),
      Brand: getOrderParam('CardBrand'),
      Name: getOrderParam('CardHolderName'),
      ApprovalCode: getOrderParam('APPROVAL'),
      TranDateTime: getOrderParam('TranDateTime'),
      TwoID: getOrderParam('TwoID'),
      Description: getOrderParam('ResponseDescription'),
    },
  };
};
